<template>
<div class="dashboard-container">
	<v-app>
		<!-- Header -->
		<div class="header-container">
			<!-- Create Document Action -->
			<div class="flex-grow-0 p-0">
				<create-document-option-button-modal
					v-on:show-incoming-document-modal="showIncomingDocumentModal"
					v-on:show-outgoing-document-modal="showOutgoingDocumentModal">
				</create-document-option-button-modal>
			</div>
			<!-- Search Box -->
			<div class="flex-grow-1 ml-1 mb-1">
				<document-search-box
					v-bind:favorite-prop="true">
				</document-search-box>
			</div>
		</div>
		<!-- Advance Search -->
		<div>
			<advance-search
				ref="advanceSearch"
				v-bind:doc-type-prop="'Favorite'"
				v-bind:category-options-prop="categoryOptions"
				v-on:apply-advance-filter="applyAdvanceFilter">
			</advance-search>
		</div>
		<hr class="bottom-devider"/>
		<!-- Body -->
		<div class="body-container">
			<div class="p-2 position-relative mb-10" style="min-height:150px">
				<div v-if="renderData" class="loader content-center p-2">
					<v-progress-circular class="loadingDoc" indeterminate color="red"></v-progress-circular>
				</div>
				<!-- Document Card -->
				<CRow>
					<CCol v-for="(item, index) in document.data" :key="index" col="4">
						<document-card
							v-bind:item-prop="item"
							v-on:show-disposition-form="showDispositionForm">
						</document-card>
					</CCol>
				</CRow>
				<div class="p-5" v-if="document.data.length == 0 && !renderData">
					<p class="text-center">Maaf, Surat Tidak Ditemukan.</p>
				</div>
			</div>
			<div class="content-center">
				<CPagination :activePage.sync="pagination.activePage" :pages="pagination.pages" align="center"/>
			</div>
		</div>

		<!-- Incoming Document Popup Modal -->
		<incoming-document-form
			:popup-modal-prop="modalIncomingDocument"
			:category-options-prop="categoryOptions"
			:tag-options-prop="pDispositionForm.receiverOptions"
			@close-popup-modal="closeIncomingDocumentModal">
		</incoming-document-form>
		<!-- Outgoing Document Popup Modal -->
		<outgoing-document-form
			:popup-modal-prop="modalOutgoingDocument"
			:category-options-prop="categoryOptions"
			:tag-options-prop="pDispositionForm.receiverOptions"
			@close-popup-modal="closeOutgoingDocumentModal">
		</outgoing-document-form>
		<!-- Disposition Popup Modal & Input Form -->
		<disposition-form
			:popup-modal-prop="pDispositionForm.popupModal"
			:doc-id-prop="pDispositionForm.input.docId"
			:memo-record-id-prop="pDispositionForm.input.memoRecordId"
			:user-options-prop="pDispositionForm.receiverOptions"
			@close-popup-modal="closeDispositionForm">
		</disposition-form>
	</v-app>
</div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { mapState } from 'vuex'
const docBiasa = require('@/assets/img/document/d3.jpeg')
import AdvanceSearch from '@/components/document/AdvanceSearch.vue'
import CreateDocumentOption from '@/components/document/CreateDocumentOptionButtonModal.vue'
import DispositionForm from '@/components/document/DispositionFormNew.vue'
import DocumentCard from '@/components/document/DocumentCard.vue'
import DocumentSearchBox from '@/components/document/SearchBox.vue'
import IncomingDocumentForm from '@/components/document/IncomingDocument.vue'
import OutgoingDocumentForm from '@/components/document/OutgoingDocument.vue'
export default {
	names: 'Favorite',
    components: {
		'advance-search': AdvanceSearch,
		'create-document-option-button-modal': CreateDocumentOption,
        'document-card': DocumentCard,
		'document-search-box': DocumentSearchBox,
		'disposition-form': DispositionForm,
        'incoming-document-form': IncomingDocumentForm,
        'outgoing-document-form': OutgoingDocumentForm
    },
	data () {
		const document = {
			data: [],
			filters: {
				isFavorite: true
			},
			sorts: null
		}
		const pagination = {
			itemsPerPage: 9,
			activePage: 1,
			pages: 0
		}
		const pDispositionForm = {
			receiverOptions: [],
			popupModal: {
				isShow: false,
				modalTitle: 'Disposisi Surat'
			},
			input: {
                docId: 0,
                memoRecordId: null
			},
			defaultValue: {
                docId: 0,
                memoRecordId: null
			}
		}
		const categoryOptions = []
		const modalIncomingDocument = {
			isShow: false
		}
		const modalOutgoingDocument = {
			isShow: false
		}
		return {
			renderData: true,
			document,
			pagination,
			pDispositionForm,
			categoryOptions,
			modalIncomingDocument,
			modalOutgoingDocument
		}
	},
	computed: {
		...mapState('auth', {
			loggedUserId: 'user_id',
			loggedUserPosition: 'position'
		}),
		activePage() {
			return this.pagination.activePage;
		}
	},
	watch: {
		...mapState('auth', {
			loggedUserId: 'user_id'
		}),
		activePage(){
			this.renderData = true
			this.getDocumentList()
				.then( data => {
					this.document.data = data.items
					this.pagination.pages = Math.ceil(data.total/this.pagination.itemsPerPage)
					this.renderData = false
				})
		}
    },
	mounted () {
		this.getDocumentList()
			.then( data => {
				this.document.data = data.items
				this.pagination.pages = Math.ceil(data.total/this.pagination.itemsPerPage)
				this.renderData = false
			})
		this.getCategoryOptions()
		// Disposition
		this.getReceiverOptions()
	},
	methods: {
		// Filter
		applyAdvanceFilter (params) {
			this.document.data = []
			let advanceSearchComp = this.$refs.advanceSearch
			advanceSearchComp.loading = true
			this.document.filters = params.filters
			this.document.sorts = params.sorts
			this.getDocumentList()
				.then( data => {
					this.document.data = data.items
					this.pagination.pages = Math.ceil(data.total/this.pagination.itemsPerPage)
					this.renderData = false
					advanceSearchComp.refreshFilterBadges()
				}).catch( err => {
					console.log('Failed fetch document data with filter')
					console.log(err)
				}).finally(() => {
					advanceSearchComp.loading = false
				})
		},
		getCategoryOptions () {
			this.$store.dispatch(
				{
					type: 'document/getCategory',
					pagination: {
						limit: 100,
						offset: 0
					},
					order: {
						orderType: 'asc'
					}
				}
			).then( res => {
				let items = []
				res.result.forEach((item) => {
					let rowData = {
						id: item.category_id,
						name: item.category_name,
						label: item.category_name
                    }
					items.push(rowData)
				})
				
                this.categoryOptions = items
			})
		},
        getReceiverOptions () {
			this.$store.dispatch(
				{
					type: 'user/getUser',
					pagination: {
						limit: 200,
						offset: 0
					},
					order: {
                        orderBy: 'fullname',
                        orderType: 'asc'
                    },
                    filter: {
                        isActive: 'true'
                    }
				}
			).then( res => {
				let items = []
				for (let i = 0; i < res.result.length; i++) {
					const item = res.result[i];
					let position = (item.position != null) ? item.position : ''
					let positionExisted = items.some(el => el.position === position)
					if ((item.position != this.loggedUserPosition) && !positionExisted) {
						let label = position.trim()
						let rowData = {
							label: label,
							id: item.user_id,
							userId: item.user_id,
							fullname: item.fullname,
							email: item.email,
							rank: item.rank,
							position: item.position,
							command: null
						}
						items.push(rowData)
					}
				}
				
				this.pDispositionForm.receiverOptions = items
			})
        },
		getDocumentList () {
			this.loading = true
			return new Promise((resolve, reject) => {
				this.$store.dispatch(
					{
						type: 'document/getDocument',
						pagination: {
							limit: this.pagination.itemsPerPage,
							offset: (this.pagination.activePage-1)*this.pagination.itemsPerPage
						},
						filter: this.document.filters,
						sort: this.document.sorts
					}
				).then( res => {
					let items = []
					let total = (res.total_count)?res.total_count:res.result.length
					res.result.forEach((item) => {
						items.push(item)
					})
					
					setTimeout(() => {
						resolve({
							items,
							total
						})
					}, 1000)
				})
			})
		},
		// Navigation and Functions
		// Incoming Document
		showIncomingDocumentModal () {
			this.modalIncomingDocument.isShow = true
		},
		closeIncomingDocumentModal () {
			this.modalIncomingDocument.isShow = false
		},
		// Outgoing Document
		showOutgoingDocumentModal () {
			this.modalOutgoingDocument.isShow = true
		},
		closeOutgoingDocumentModal () {
			this.modalOutgoingDocument.isShow = false
		},
		// Disposition
		showDispositionForm (item) {
            if (item != null) {
				let memoRecordId = (item.disposition && item.disposition.memo_record_id) ? item.disposition.memo_record_id : null
				this.pDispositionForm.input.docId = item.doc_id
				this.pDispositionForm.input.memoRecordId = memoRecordId
				this.pDispositionForm.popupModal.isShow = true
            }
		},
		closeDispositionForm () {
			this.pDispositionForm.popupModal.isShow = false
            this.pDispositionForm.input = Object.assign({}, this.pDispositionForm.defaultValue)
		}
	}
}
</script>

<style scoped lang="scss">
#app {
	background-color: #f5f5f5;
}
.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
</style>